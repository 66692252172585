<template>
  <v-container
    fluid
    class="loginContainer"
    :style="{
      'background-image': 'url(https://picsum.photos/1024/768?random)'
    }"
  >
    <v-row class="my-0" no-gutters>
      <v-col
        order="2"
        order-md="1"
        cols="12"
        class="rounded-xl"
        :md="mode != 2 ? '6' : '10'"
        :style="{
          'background-color': 'var(--primary)',
          opacity: '.85'
        }"
        align-self="center"
      >
        <v-card class="infocard elevation-3">
          <div
            class="d-flex flex-row justify-content-end align-items-center pt-1"
          >
            <i-btn
              :small="$vuetify.breakpoint.xsOnly"
              text
              @click="changeMode"
              :title="activeTab"
              color="primary"
              :icon="`far fa-${
                activeTab === 'banners' ? 'image' : 'lightbulb'
              }`"
            />
            <i-locale login />
          </div>
          <v-card-title class="d-flex flex-column-reverse justify-center">
            <img class="login--logo" alt="Logo" :src="img" />
            <span class="ml-4" v-if="company !== undefined">
              {{ company.name }}
            </span>
          </v-card-title>
          <v-card-text>
            <ValidationObserver v-slot="{ handleSubmit, reset }">
              <form
                v-if="mode === 1"
                @submit.prevent="handleSubmit(login)"
                @reset.prevent="reset"
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <ValidationProvider
                      vid="username"
                      :name="$tc('username', 1)"
                      rules="required|min:1"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        autocomplete="username"
                        v-model="user.username"
                        :label="$tc('username', 1)"
                        @keydown="$emit('submit')"
                        :error-messages="errors[0]"
                        outlined
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <ValidationProvider
                      vid="password"
                      :name="$tc('password', 1)"
                      rules="required|min:8|max:64"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        autocomplete="current-password"
                        outlined
                        :append-icon="showPass ? 'fa-eye' : 'fa-eye-slash'"
                        :error-messages="errors[0]"
                        :label="$tc('password', 1)"
                        :type="showPass ? 'text' : 'password'"
                        @keydown="$emit('submit')"
                        @click:append="showPass = !showPass"
                        v-model="user.password"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn color="primary" :loading="load" type="submit">
                      <v-icon class="pr-2">fa-paper-plane</v-icon>
                      {{ $t('login') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="text-center mt-2">
                    <v-btn small color="secondary" text @click="mode = 3">
                      {{ $t('forgot') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
              <register v-if="mode === 2" @registered="mode = 1"></register>
              <password-lost
                v-else-if="mode === 3"
                @registered="mode = 1"
              ></password-lost>
            </ValidationObserver>
          </v-card-text>
          <p class="caption text-center">
            <strong>
              &copy; 2018 - {{ new Date().getFullYear() }} IPrintPOS.
            </strong>
            {{ $tc('copyright') }}
          </p>
        </v-card>
      </v-col>
      <v-col
        order="1"
        order-md="2"
        cols="12"
        md="6"
        justify="center"
        align-self="center"
        class="px-2"
      >
        <carrousel-tips v-if="tips.length > 0" v-model="tips" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import register from './register.vue'
import passwordLost from './passwordLost.vue'
import CarrouselTips from '../../components/carrouselTips/CarrouselTips.vue'
export default {
  components: {
    CarrouselTips,
    register,
    passwordLost
  },
  data() {
    return {
      tips: [],
      user: {
        username: '',
        password: ''
      },
      mode: 1,
      img: '',
      showPass: false,
      load: false,
      activeTab: 'banners'
    }
  },
  watch: {
    async logo(val) {
      if (val !== undefined && val !== null) {
        this.img = await this.showImage(val)
      } else {
        this.img = require('@/assets/logo-blue.png')
      }
    }
  },
  computed: {
    ...mapGetters({
      logo: 'company/getLogo',
      company: 'company/getCompanyData'
    })
  },
  async mounted() {
    this.$vuetify.theme.dark = false
    this.getCarrousel()
    if (this.logo !== undefined && this.logo !== null) {
      this.img = await this.showImage(this.logo)
    } else {
      this.img = require('@/assets/logo-blue.png')
    }
  },
  methods: {
    changeMode() {
      switch (this.activeTab) {
        case 'banners':
          this.activeTab = 'tips'
          break
        default:
          this.activeTab = 'banners'
          break
      }
      this.getCarrousel()
    },
    async getCarrousel() {
      try {
        this.loading = true
        let response = await this.$api.loginCarrousel.list({
          opt: { params: { active: true, mode: this.activeTab } }
        })
        this.tips = response.data
      } finally {
        this.loading = false
      }
    },
    async login() {
      try {
        this.load = true
        let token = await this.$api.auth.login({ form: this.user })
        const data = {
          token: token.data.token,
          refresh: token.data.refresh
        }
        this.$store.dispatch('session/login', data)
        this.loadMe()
        this.$router.push({ name: 'index' })
      } catch (error) {
        console.error(error)
      } finally {
        this.load = false
      }
    },
    /**
     * loadMe
     * Método para asignar los datos del usuario y la compañía a las variables
     * del store global.
     * @me: Datos del usuario logueado
     * @$vuetify.theme.dark: Dark mode del usuario
     * @myFirstCompany: primera compañía a la que está asociada el usuario
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async loadMe() {
      let me = await this.$api.user.me({})
      me = me.data
      this.$store.commit('session/setMe', me)
      this.$vuetify.theme.dark = me.profile.darkMode

      if (me.companies.length > 0) {
        const myFirstCompany = await this.$api.company.show({
          pk: me.companies[0].id
        })
        this.$store.commit('company/companyData', myFirstCompany.data)
        this.$toast.success(
          `${this.$t('welcome_company', {
            value: this.$tc(myFirstCompany.data.name)
          })}`
        )
      }
    }
  }
}
</script>
<style lang="sass" scoped>
::v-deep.loginContainer
  height: 100vh
  background-size: cover
  .row
    height: 100%
    .col-12 .infocard.v-card
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
::v-deep.login--logo
  width: 450px
  max-width: 100%
  height: auto
  margin-top: 10px
  margin-bottom: 10px
  padding-left: 25px
  padding-right: 25px
</style>
